
        /********************************************************************************/

@import "@radically-straightforward/javascript/static/index.css";
@import "@fontsource-variable/public-sans";
@import "bootstrap-icons/font/bootstrap-icons.css";

input[type="text"],
button {
  --csstools-light-dark-toggle--0: var(--csstools-color-scheme--dark) var(--color--slate--50);
  background-color: var(--csstools-light-dark-toggle--0, var(--color--slate--950));
  padding: var(--space--1) var(--space--2);
  --csstools-light-dark-toggle--1: var(--csstools-color-scheme--dark) var(--color--slate--400);
  border: var(--border-width--1) solid
    var(--csstools-light-dark-toggle--1, var(--color--slate--600));
  border-radius: var(--border-radius--1);
  transition-property: var(--transition-property--colors);
  transition-duration: var(--transition-duration--150);
  transition-timing-function: var(--transition-timing-function--ease-in-out);
  &:focus-within {
    --csstools-light-dark-toggle--2: var(--csstools-color-scheme--dark) var(--color--blue--500);
    border-color: var(--csstools-light-dark-toggle--2, var(--color--blue--500));
  }

@supports (color: light-dark(red, red)){
&:focus-within {
    border-color: light-dark(var(--color--blue--500), var(--color--blue--500));
  }
}
}

@supports (color: light-dark(red, red)){
input[type="text"],
button {
  background-color: light-dark(
    var(--color--slate--50),
    var(--color--slate--950)
  );
  border: var(--border-width--1) solid
    light-dark(var(--color--slate--400), var(--color--slate--600))
}
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: underline;
  --csstools-light-dark-toggle--3: var(--csstools-color-scheme--dark) var(--color--blue--500);
  color: var(--csstools-light-dark-toggle--3, var(--color--blue--500));
  transition-property: var(--transition-property--colors);
  transition-duration: var(--transition-duration--150);
  transition-timing-function: var(--transition-timing-function--ease-in-out);
  &:hover,
  &:focus-within {
    --csstools-light-dark-toggle--4: var(--csstools-color-scheme--dark) var(--color--blue--400);
    color: var(--csstools-light-dark-toggle--4, var(--color--blue--400));
  }

@supports (color: light-dark(red, red)){
&:hover,
  &:focus-within {
    color: light-dark(var(--color--blue--400), var(--color--blue--400));
  }
}
  &:active {
    --csstools-light-dark-toggle--5: var(--csstools-color-scheme--dark) var(--color--blue--600);
    color: var(--csstools-light-dark-toggle--5, var(--color--blue--600));
  }

@supports (color: light-dark(red, red)){
&:active {
    color: light-dark(var(--color--blue--600), var(--color--blue--600));
  }
}
}

@supports (color: light-dark(red, red)){
a {
  color: light-dark(var(--color--blue--500), var(--color--blue--500))
}
}

h2 {
  font-weight: 700;
}

hr {
  --csstools-light-dark-toggle--6: var(--csstools-color-scheme--dark) var(--color--slate--200);
  border-top: var(--border-width--1) solid
    var(--csstools-light-dark-toggle--6, var(--color--slate--800));
}

@supports (color: light-dark(red, red)){
hr {
  border-top: var(--border-width--1) solid
    light-dark(var(--color--slate--200), var(--color--slate--800));
}
}

small {
  font-size: var(--font-size--3);
  line-height: var(--font-size--3--line-height);
  font-weight: 700;
  --csstools-light-dark-toggle--7: var(--csstools-color-scheme--dark) var(--color--slate--500);
  color: var(--csstools-light-dark-toggle--7, var(--color--slate--500));
}

@supports (color: light-dark(red, red)){
small {
  color: light-dark(var(--color--slate--500), var(--color--slate--500));
}
}

.tippy-box {
  font-weight: 400;
  --csstools-light-dark-toggle--8: var(--csstools-color-scheme--dark) var(--color--black);
  color: var(--csstools-light-dark-toggle--8, var(--color--white));
  --csstools-light-dark-toggle--9: var(--csstools-color-scheme--dark) var(--color--slate--50);
  background-color: var(--csstools-light-dark-toggle--9, var(--color--slate--950));
  --csstools-light-dark-toggle--10: var(--csstools-color-scheme--dark) var(--color--slate--400);
  border: var(--border-width--1) solid
    var(--csstools-light-dark-toggle--10, var(--color--slate--600));
  border-radius: var(--border-radius--1);
  box-shadow: var(--box-shadow--4);
  &[data-theme~="error"] {
    --csstools-light-dark-toggle--11: var(--csstools-color-scheme--dark) var(--color--red--800);
    color: var(--csstools-light-dark-toggle--11, var(--color--red--200));
    --csstools-light-dark-toggle--12: var(--csstools-color-scheme--dark) var(--color--red--50);
    background-color: var(--csstools-light-dark-toggle--12, var(--color--red--950));
    --csstools-light-dark-toggle--13: var(--csstools-color-scheme--dark) var(--color--red--400);
    border-color: var(--csstools-light-dark-toggle--13, var(--color--red--600));
  }

@supports (color: light-dark(red, red)){
&[data-theme~="error"] {
    color: light-dark(var(--color--red--800), var(--color--red--200));
    background-color: light-dark(var(--color--red--50), var(--color--red--950));
    border-color: light-dark(var(--color--red--400), var(--color--red--600));
  }
}
  .tippy-content {
    padding: var(--space--1) var(--space--2);
  }
}

@supports (color: light-dark(red, red)){
.tippy-box {
  color: light-dark(var(--color--black), var(--color--white));
  background-color: light-dark(
    var(--color--slate--50),
    var(--color--slate--950)
  );
  border: var(--border-width--1) solid
    light-dark(var(--color--slate--400), var(--color--slate--600))
}
}




        @layer __RADICALLY_STRAIGHTFORWARD__INLINE__ {
          /********************************************************************************/

@layer gaysqtxdmuicrk {
[css~="gaysqtxdmuicrk"] {
  --csstools-color-scheme--dark:  ;
  color-scheme: light dark;
}
@media (prefers-color-scheme: dark){
[css~="gaysqtxdmuicrk"] {
  --csstools-color-scheme--dark: initial;
}
    }

}

/********************************************************************************/

@layer felndlpjdflgmt {
[css~="felndlpjdflgmt"] {
  font-family: "Public Sans Variable", var(--font-family--sans-serif);
  font-size: var(--font-size--3-5);
  line-height: var(--font-size--3-5--line-height);
  --csstools-light-dark-toggle--14: var(--csstools-color-scheme--dark) var(--color--black);
  color: var(--csstools-light-dark-toggle--14, var(--color--white));
  --csstools-light-dark-toggle--15: var(--csstools-color-scheme--dark) var(--color--white);
  background-color: var(--csstools-light-dark-toggle--15, var(--color--black));
  padding: var(--space--4) var(--space--4);
}

@supports (color: light-dark(red, red)){
[css~="felndlpjdflgmt"] {
  color: light-dark(var(--color--black), var(--color--white));
  background-color: light-dark(var(--color--white), var(--color--black));
}
}

}

/********************************************************************************/

@layer evihcnjzizuqad {
[css~="evihcnjzizuqad"] {
  max-width: var(--space--144);
  margin: var(--space--0) auto;
  display: flex;
  flex-direction: column;
  gap: var(--space--4);
}

}

/********************************************************************************/

@layer epedmvqygoehkc {
[css~="epedmvqygoehkc"] {
  font-size: var(--font-size--4-5);
  line-height: var(--font-size--4-5--line-height);
  font-weight: 700;
}

}

/********************************************************************************/

@layer etfzjitihwiweo {
[css~="etfzjitihwiweo"] {
  text-decoration: none;
  &:not(:hover, :focus-within, :active) {
    --csstools-light-dark-toggle--16: var(--csstools-color-scheme--dark) var(--color--black);
    color: var(--csstools-light-dark-toggle--16, var(--color--white));
  }

@supports (color: light-dark(red, red)){
&:not(:hover, :focus-within, :active) {
    color: light-dark(var(--color--black), var(--color--white));
  }
}
  display: inline-flex;
  gap: var(--space--2);
}

}

/********************************************************************************/

@layer ctcpaefngdseol {
[css~="ctcpaefngdseol"] {
  margin-top: var(--space---1);
}

}

/********************************************************************************/

@layer burmszyfqgrarv {
[css~="burmszyfqgrarv"] {
  text-align: center;
  --csstools-light-dark-toggle--17: var(--csstools-color-scheme--dark) var(--color--green--800);
  color: var(--csstools-light-dark-toggle--17, var(--color--green--200));
  --csstools-light-dark-toggle--18: var(--csstools-color-scheme--dark) var(--color--green--50);
  background-color: var(--csstools-light-dark-toggle--18, var(--color--green--950));
  max-width: var(--space--96);
  padding: var(--space--1) var(--space--2);
  --csstools-light-dark-toggle--19: var(--csstools-color-scheme--dark) var(--color--green--400);
  border: var(--border-width--1) solid
    var(--csstools-light-dark-toggle--19, var(--color--green--600));
  border-radius: var(--border-radius--1);
  box-shadow: var(--box-shadow--4);
  margin: var(--space--0) auto;
  position: fixed;
  top: var(--space--8);
  left: var(--space--2);
  right: var(--space--2);
}

@supports (color: light-dark(red, red)){
[css~="burmszyfqgrarv"] {
  color: light-dark(var(--color--green--800), var(--color--green--200));
  background-color: light-dark(
    var(--color--green--50),
    var(--color--green--950)
  );
  border: var(--border-width--1) solid
    light-dark(var(--color--green--400), var(--color--green--600));
}
}

}

/********************************************************************************/

@layer cfqzzkowepjuir {
[css~="cfqzzkowepjuir"] {
  display: flex;
  gap: var(--space--2);
  @media (max-width: 400px) {
    flex-direction: column;
  }
}

}

/********************************************************************************/

@layer grbtayhnoadufa {
[css~="grbtayhnoadufa"] {
  flex: 1;
}

}

/********************************************************************************/

@layer dmqwlizufpfdhx {
[css~="dmqwlizufpfdhx"] {
  display: flex;
  flex-direction: column;
  gap: var(--space--4);
}

}

/********************************************************************************/

@layer bekgtjlajbvyyh {
[css~="bekgtjlajbvyyh"] {
  width: 100%;
}

}

/********************************************************************************/

@layer droxglsrvpohn {
[css~="droxglsrvpohn"] {
  --csstools-light-dark-toggle--20: var(--csstools-color-scheme--dark) var(--color--red--500);
  color: var(--csstools-light-dark-toggle--20, var(--color--red--500));
}

@supports (color: light-dark(red, red)){
[css~="droxglsrvpohn"] {
  color: light-dark(var(--color--red--500), var(--color--red--500));
}
}

}


        }
      